@import '~antd/dist/antd.css';
@import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-menu-vertical
  > .ant-menu-item
  .ant-menu-vertical
  > .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  height: 2.8em;
}

.ant-menu-vertical {
  border-right: none;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  border-radius: 5px;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']),
.ant-table,
.ant-table-thead {
  border-radius: 10px;
}

.ant-card-bordered {
  border-radius: 10px;
}

.ck,
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker,
.ant-picker-panel-container {
  border-radius: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.ant-checkbox-inner,
.ant-popover-inner {
  border-radius: 5px;
}

.ant-btn {
  border-radius: 5px !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button,
.ant-upload-list-item {
  border-radius: 0 7px 7px 0;
}
